import React from 'react'
import Container from "react-bootstrap/esm/Container";
import { Row, Col } from "react-bootstrap";
import { Card} from "react-bootstrap";
import { Link } from 'react-router-dom';
import Whatsaapicon from '../components/Whatsaapicon';


const Blog = () => {
	document.title = "Blog - Easy Laser Tech ";

	return (
	  
		<div className='bg-light'>
  <br/>
  <Whatsaapicon/>
  
  
  
	  <div className='text-center' style={{backgroundImage:`url("./Images/background/blog-bg.jpg")`, height:"60vh",width:"100%",backgroundSize:"cover"}}>
	  <h1 className=' fw-bold' style={{color:"#ffff",paddingTop:"105px"}}>BLOG</h1>
	  <h6 style={{color:"#ffff"}} className="fw-bold">HOME / <span style={{color:" #61dafb"}}>BLOG</span></h6>
  </div>
	  <br/>
  
  
	<Container className="mt-5 "> 
	<Row className="justify-content-center">
	<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/owner_blog"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					style={{height:"60vh",objectFit:"cover",objectPosition:"top"}}
					
					src= 
					'../Images/blog/owner.jpeg' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center'>Introducing Kishor Padsala, Director of Easy Laser Tech</h4> 
				   
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col>  
		<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/alm_blog1"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					style={{height:"60vh"}}
					src= 
					'./Images/blog/advantage_blog.jpeg' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center text-capitalize'>Advantages Of Laser Marking Machine In Steel Industries</h4> 
				                                                                                
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col> 

		<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/rlm_blog2"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					src= 
					'./Images/blog/blo_ 2.png' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center'> How To Choose Right Laser Marking Machine</h4> 
				                        
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col> 

		<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/reasons_blog3"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					src= 
					'./Images/blog/blo_3.png' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center'>5 Reasons To Consider Easy Laser Tech 3 In 1 Laser Welding Machine</h4> 
				   
				</Card.Body> 
				</Link>
				</Card> 
				 
		</Col> 

		<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/SafetyAndPrecaution_Blog4"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					src= 
					'./Images/blog/blo_4.png' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center'> Precautions And Saftey While Using Laser Marking Machine</h4> 
				                             
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col> 

		<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/lmdi_blog"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					src= 
					'./Images/blog/blo_1245.png' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center'> Application Of Laser Marking In Different Industries</h4> 
				                           
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col> 
		

		<Col xs={12} md={6} lg={4} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/PowerofHandheldLaserMarkingMachines_Blog6"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					src= 
					'./Images/blog/blo_6.jpeg' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h4 className='text-center'>Revolutionizing Manufacturing: The Power of
				Handheld Laser Marking Machines</h4> 
				   
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col> 
		</Row>
		</Container>
		</div>
	


  )
}

export default Blog