import React from 'react'
import { Container,Image } from 'react-bootstrap'
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom';

function GreenSawingShapeCVD() {
  return (
	<>
	<br/>
	<br/>
	<Container className='text-center'>
	<h2 className='text-center my-4' style={{color:"#199AD8"}} >Green Laser Machine for Sawing, Shape Cutting, CVD Coring and Slicing</h2>
	<Image src= './Images/Product Img/diamond/green 02.png'  style={{height:"100%",width:"50%"}} 
	/>
	</Container>
	<Container>
	<p> Green Laser Machine and widely acclaimed by industry leaders for its superior performance. It is very compact equipment and has an elegant appearance. The Green Laser System, uniquely set up inside the machine, generates the desired laser beam for diamond and CVD ( Synthetic Diamond) processing. The smart combination of mechatronics, an advance user-friendly software and the operating system delivers exceptional sawing and shaping results.India’s first machine accurately performing Auto PIE Sawing in a single operation.</p>
	
	<h3>General Features :</h3>
												
	<ul class="list-check">

<li>Superior pulse to pulse stability, with inbuilt Peak Power Regulation, imparts high safety in the cutting process.</li>
									
<li>Field-proven, long-lasting Optical Coating and Laser Diode Module</li>
									
<li>Smart Interlock system guarantees the safe and smooth running of the machine</li>
									
<li>Highly engineered and precisely sealed Laser Resonator that ensures nil deviation in output power caused due to vibration and change in environmental condition</li>
									
<li>High quality, long life and maintenance-free motion system</li>
									
<li>Granite base offers vibration-free operation</li>
									
<li>Excellent surface quality throughout the entire depth of cut</li>
									
<li>Motorized direct driven 360⁰ batch production fixture for :  <ul><li>* Precise Single Side or Double Side Sawing</li> <li>* Accurate and precise auto pie sawing from two side or single side sawing</li> <li>* Smart feature to set Multiple sawing planes for cutting the extra pieces in a single operation</li> <li>* Slicing of CVD with excellent surface finish</li></ul></li>
									
<li>Batch production fixture for very accurate coring of CVD</li>
									
<li>Skin banding option for eliminating the risk of diamond breakage because of internal reflection.</li>
									
<li>A compact and robust frame structure</li>
																					   </ul> 
	</Container>





	<Container className="mt-5 "> 
  <Row className="justify-content-center"> 
	  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	 
	  <Card className='shado'> 
	  <Link to={"/GreenHighValue"} style={{textDecoration:"none"}}>
	    <Card.Img
				  variant="top"
				  src= 
				  './Images/Product Img/diamond/green 04.png' 
				  alt="GeeksforGeeks Logo"
			  /> 
			 
			  <Card.Body> 
			  <h5 className='text-center'>Green Laser Machine for High Valued Performance</h5> 
				 
			  </Card.Body> 
			  </Link>
			  </Card> 
			  
	  </Col> 
	  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		  <Card className='shado'> 
		  <Link to={"/IrGreen4p"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
				  './Images/Product Img/diamond/green 01.png' 
			  /> 
			  <Card.Body > 
				  <h5 className='text-center'>IR/Green Laser Machine for 4P and Faceting operation of Round and Fancy Shapes</h5> 
				
			  </Card.Body>
			  </Link> 
		  </Card> 
	  </Col> 

	 
  

  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
  <Card className='shado'> 
  <Link to={"/GreenSawingCVDSlicing"} style={{textDecoration:"none"}}>
	  <Card.Img 
		  variant="top"
		  src= 
		  './Images/Product Img/diamond/green 03.png' 
		  alt="GeeksforGeeks Logo"
	  /> 
	  <Card.Body> 
	  <h5 className='text-center'>Green Laser Machine for Sawing, CVD Coring and Slicing</h5> 
		 
	  </Card.Body> 
	  </Link>
  </Card> 
</Col> 
   
</Row>
</Container>


	</>
  )
}

export default GreenSawingShapeCVD