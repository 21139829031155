import React from 'react'
import Whatsaapicon from './components/Whatsaapicon'
import Container from "react-bootstrap/esm/Container";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";

function Gallery() {
	document.title = "Gallery - Easy Laser Tech ";
  return (
	<>
	<Whatsaapicon />

	<br />
	<div
	  className="text-center"
	  style={{
		backgroundImage: `url("./Images/background/gallery-bg.jpg")`,
		height: "50vh",
		width: "100%",
		backgroundSize: "cover",
	  }}
	>

	<h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
	EXHIBITION & CERTIFICATES
	</h1>
	  <h6 style={{ color: "#ffff" }} className="fw-bold">
	  HOME /
	  <span style={{ color: " #61dafb" }}>
	   GALLERY
	  </span>
	  </h6>
	  </div>
	  <br />

	  <Container className="mt-5 ">
	  <Row className="justify-content-center">

	  <Col xs={12} md={6} lg={3} className="my-1 "> 
	  <Card className='shad'> 
		  <Card.Img 
			  variant="top"
			  style={{height:"205px"}}
			  src= 
	  './Images/Exhibition/0.jpeg'
			  alt="GeeksforGeeks Logo"
		  /> 
		  <Card.Body className='shad'> 
		   
			 
		  </Card.Body> 
	  </Card> 
	  </Col> 

	  <Col xs={12} md={6} lg={3} className="my-1 "> 
	  <Card className='shad'> 
		  <Card.Img 
			  variant="top"
			  src= 
'./Images/Exhibition/1.jpeg'
			  alt="GeeksforGeeks Logo"
		  /> 
		  <Card.Body className='shad'> 
		   
			 
		  </Card.Body> 
	  </Card> 
  </Col> 

  <Col xs={12} md={6} lg={3} className="my-1 "> 
  <Card className='shad'> 
	  <Card.Img 
		  variant="top"
		  src= 
'./Images/Exhibition/2.jpeg'
		  alt="GeeksforGeeks Logo"
	  /> 
	  <Card.Body className='shad'> 
	   
		 
	  </Card.Body> 
  </Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/3.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/4.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/6.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
 <Col xs={12} md={6} lg={3} className="my-1 "> 
	  <Card className='shad'> 
		  <Card.Img 
			  variant="top"
			  src= 
'./Images/Exhibition/7.jpeg'
			  alt="GeeksforGeeks Logo"
		  /> 
		  <Card.Body className='shad'> 
		   
			 
		  </Card.Body> 
	  </Card> 
  </Col> 
  <Col xs={12} md={6} lg={3} className="my-1 "> 
  <Card className='shad'> 
	  <Card.Img 
		  variant="top"
		  src= 
'./Images/Exhibition/8.jpeg'
		  alt="GeeksforGeeks Logo"
	  /> 
	  <Card.Body className='shad'> 
	   
		 
	  </Card.Body> 
  </Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/9.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/10.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/11.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
'./Images/Exhibition/12.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
</Row>
</Container>

<br />

<h1 className='text-center ' style={{color:"#199AD8"}}> Certificate</h1>
<Container className="mt-5 ">
<Row className="justify-content-center">

<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
	
		src= 
'./Images/Exhibition/cirty1.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
		
		src= 
'./Images/Exhibition/cirty2.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 "> 
<Card className='shad'> 
	<Card.Img 
		variant="top"
	
		src= 
'./Images/Exhibition/cirty3.jpeg'
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body className='shad'> 
	 
	   
	</Card.Body> 
</Card> 
</Col> 
  
	 </Row>
	  </Container>
	  
	 
	  </>
  )
}

export default Gallery
