import React from "react";
import { Image } from "react-bootstrap";
import Whatsaapicon from "./components/Whatsaapicon";
import { Container } from "react-bootstrap";
const MultiSawing = () => {
  return (
    <>
      <Whatsaapicon />
      <br />
      <div
        className="text-center"
        style={{
          backgroundImage: `url("./Images/background/about-bg.webp")`,
          height: "50vh",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
          Fiber Multi Sawing Machine
        </h1>
        <h6 style={{ color: "#ffff" }} className="fw-bold">
          Home /
          <span style={{ color: " #61dafb" }}>Fiber Multi Sawing Machine</span>
        </h6>
      </div>
      <br />
      <Container>
        <h1 className="fw-bold mt-3 " style={{ color: "#199AD8" }}>
          Fiber Multi Sawing Machine
        </h1>

        <div className="text-center my-3">
          <Image
            src="./Images/Product Img/diamond2/multiPie.jpeg"
            alt="img"
            style={{ width: "40%" }}
          />
        </div>
      </Container>

      <Container className="mt-3">
        <h2>Specification </h2>

        <p>1. Fast production with minimum weightloss</p>

        <p>2. Best result in pie sawing</p>

        <p>3. low brakage ratio. </p>

        <p>4. smooth cutting surface with IR compare.</p>

        <p>5. low maintainance and easy operating system</p>
      </Container>
    </>
  );
};

export default MultiSawing;
