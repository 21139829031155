import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home';
import About from "./About";
import Products from "./Products.js";
import Contact from "./Contact.js";
import Video from "./Video.js";
import FLMM from "./FLMM.js";
import CO2LCEM from "./CO2LCEM.js";
import CO2LEM from "./CO2LEM.js";
import UVLMM from "./UVLMM.js";
import OLMM from "./OLMM.js";
import JSM from "./JSM.js";
import JCM from "./JCM.js";
import GTM from "./GTM.js";
import FLWM from "./FLWM.js";
import FLCM from "./FLCM.js";
import DiamondGroovingWheels from "./DiamondGroovingWheels.js";
import GreenHighValue from "./GreenHighValue.js";
import GreenSawingCVDSlicing from "./GreenSawingCVDSlicing.js";
import IrGreen4p from "./IrGreen4p.js"; 
import GreenSawingShapeCVD from "./GreenSawingShapeCVD.js";
import OzoneLaserMarkingMachine from "./OzoneLaserMarkingMachine.js";
import OzoneLaserMarkingMachineTouchScreenModel from "./OzoneLaserMarkingMachineTouchScreenModel.js";
import RockLaserMarkingMachine from "./RockLaserMarkingMachine.js"
import OptoFiberLaserMarkingMachine from "./OptoFiberLaserMarkingMachine.js";
import MegaLaserMarkingMachineforMetal from "./MegaLaserMarkingMachineforMetal.js";
import M360LaserMaekingMachine from "./M360LaserMaekingMachine.js";
import ElectroLaserMarkingMachine from "./ElectroLaserMarkingMachine.js";
import RocksideLaserMarkingMachine from "./RocksideLaserMarkingMachine.js";
import HandheldLaserMarkingMachine from "./HandheldLaserMarkingMachine.js";
import SemiFlyerLaserMarkingMachine from "./SemiFlyerLaserMarkingMachine.js";
import AutoRotaryIndexLaserMarkingMachine from "./AutoRotaryIndexLaserMarkingMachine.js";
import FullFlyerLaserMarkingMachine from "./FullFlyerLaserMarkingMachine.js";
import CloseBodyAutomationLaserMarkingMachine from "./CloseBodyAutomationLaserMarkingMachine.js";
import ConvoFlyLaserMarkingMachine from "./ConvoFlyLaserMarkingMachine.js";
import MegaLongLaserMarkingMachine from "./MegaLongLaserMarkingMachine.js";
import ErrorPage from "./ErrorPage";

	/* The following line can be included in your src/index.js or App.js file */
  
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from "./components/Header"

/* Contact */
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Footer from "./components/Footer.js";
import Gallery from "./Gallery.js";
import Blog from "./blog/Blog.js";
import ALM_BLOG1 from "./blog/ALM_BLOG1.js";
import RLM_BLOG2 from "./blog/RLM_BLOG2.js";
import Reasons_BLOG3 from "./blog/Reasons_BLOG3.js";
import SafetyAndPrecaution_Blog4 from "./blog/SafetyAndPrecaution_Blog4.js";
import LMDI_BLOG5 from "./blog/LMDI_BLOG5.js";
import PowerofHandheldLaserMarkingMachines_Blog6 from "./blog/PowerofHandheldLaserMarkingMachines_Blog6.js";
import Owner_Blog from "./blog/Owner_Blog.js";
import IrGelvo from "./IrGelvo.js";
import MultiSawing from "./MultiSawing.js";
import CvdCorning from "./CvdCorning.js";
import FiberGelvo from "./FiberGelvo.js"


const App = () => {
  return (
    	<Router>

	<Header/>
	
	
        <Routes>
          <Route path="/" element={<Home />} />
.
		  <Route path="/about" element={<About/>}/>
		  <Route path="/products" element={<Products/>}/>
          <Route path="/contact" element={<Contact />} />
		  <Route path="/video" element={<Video />} />
		  <Route path="/gallery" element={<Gallery/>}/>
		//   blog
		  <Route path="/blog" element={<Blog/>}/>
		  <Route path="/owner_blog" element={<Owner_Blog/>}/>
		  <Route path="/alm_blog1" element={<ALM_BLOG1/>}/>
		  <Route path="/rlm_blog2" element={<RLM_BLOG2/>}/>
		  <Route path="/reasons_blog3" element={<Reasons_BLOG3/>}/>
		  <Route path="/SafetyAndPrecaution_Blog4" element={<SafetyAndPrecaution_Blog4/>}/>
		  <Route path="/lmdi_blog" element={<LMDI_BLOG5/>}/>
		  <Route path="/PowerofHandheldLaserMarkingMachines_Blog6" element={<PowerofHandheldLaserMarkingMachines_Blog6/>}/>
		  <Route path="DiamondGroovingWheels" element={<DiamondGroovingWheels/>}/>
          <Route path="/flmm" element={<FLMM />} />
		  <Route path="/flcm" element={<FLCM />} />
		  <Route path="/flwm" element={<FLWM />} />
		  <Route path="/gtm" element={<GTM />} />
		  <Route path="/jcm" element={<JCM />} />
		  <Route path="/jsm" element={<JSM />} />
		  <Route path="/olmm" element={<OLMM />} />
		  <Route path="/uvlmm" element={<UVLMM />} />
		  <Route path="/co2lcem" element={<CO2LCEM />} />
		  <Route path="/co2lem" element={<CO2LEM />} />
		  <Route path="/flmm" element={<FLMM />} />
		  <Route path="/GreenHighValue" element={<GreenHighValue/>} />
		  <Route path="/GreenSawingCVDSlicing" element={<GreenSawingCVDSlicing />} />

		  <Route path="/IrGreen4p" element={<IrGreen4p />} />
		  <Route path="/GreenSawingShapeCVD" element={<GreenSawingShapeCVD />} />
        //  Diamond

		
		<Route path="/multisawing" element={<MultiSawing/>} />
		<Route path="/fibergelvo" element={<FiberGelvo/>} />
		<Route path="/cvdcorning" element={<CvdCorning/>} />
		<Route path="/irgelvo" element={<IrGelvo/>} />


		// fiber laser marking


		<Route path="/OzoneLaserMarkingMachine" element={<OzoneLaserMarkingMachine />} />
		<Route path="/OzoneLaserMarkingMachineTouchScreenModel" element={<OzoneLaserMarkingMachineTouchScreenModel />} />
		<Route path="/RockLaserMarkingMachine" element={<RockLaserMarkingMachine />} />
		<Route path="/OptoFiberLaserMarkingMachine" element={<OptoFiberLaserMarkingMachine />} />
		<Route path="/MegaLaserMarkingMachineforMetal" element={<MegaLaserMarkingMachineforMetal />} />
		<Route path="/M360LaserMaekingMachine" element={<M360LaserMaekingMachine />} />
		<Route path="/ElectroLaserMarkingMachine" element={<ElectroLaserMarkingMachine />} />
		<Route path="/RocksideLaserMarkingMachine" element={<RocksideLaserMarkingMachine />} />
		<Route path="/HandheldLaserMarkingMachine" element={<HandheldLaserMarkingMachine />} />
		<Route path="/SemiFlyerLaserMarkingMachine" element={<SemiFlyerLaserMarkingMachine />} />
		<Route path="/AutoRotaryIndexLaserMarkingMachine" element={<AutoRotaryIndexLaserMarkingMachine />} />
		<Route path="/FullFlyerLaserMarkingMachine" element={<FullFlyerLaserMarkingMachine />} />
		<Route path="/CloseBodyAutomationLaserMarkingMachine" element={<CloseBodyAutomationLaserMarkingMachine />} />
		<Route path="/ConvoFlyLaserMarkingMachine" element={<ConvoFlyLaserMarkingMachine />} />
		<Route path="/MegaLongLaserMarkingMachine" element={<MegaLongLaserMarkingMachine />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
		
		<Footer />
      </Router>
	  
  );
};

export default App;
