import React from 'react'
import Whatsaapicon from '../components/Whatsaapicon';
import { Container} from 'react-bootstrap';
import {Image} from "react-bootstrap";

const Owner_Blog = () => {
	document.title = "Introducing Kishor Padsala, Director of Easy Laser Tech-Blog || Easy Laser Tech ";
	return (
	
		<>
		  <div className='bg-light'>
	<br/>
	<Whatsaapicon/>
	
	
		<div className='text-center' style={{backgroundImage:`url("./Images/background/about-bg.webp")`, height:"60vh",width:"100%",backgroundSize:"cover"}}>
		<h2 className=' fw-bold' style={{color:"#ffff",paddingTop:"105px"}}>Introducing Kishor Padsala, Championing Innovation</h2>
		<h6 style={{color:"#ffff"}} className="fw-bold">BLOG / <span style={{color:" #61dafb"}}>Introducing Kishor Padsala, Championing Innovation</span></h6>
	</div>
		<br/>
		</div>
		<Container>
		<h2 className="text-center my-4 fw-bold" style={{color:"#199AD8"}} >Introducing Kishor Padsala, Director of Easy Laser Tech</h2>
	   <Image src="./Images/blog/owner.jpeg" alt="blog" className='h-full w-full object-cover object-left-top'/> 
		</Container>
		<Container>
		<p style={{textAlign:"justify"}}>At the helm of EASY LASER TECH, Kishor padsala stands as a testament to
		what entrepreneurial spirit combined with technical prowess can achieve. With
		a background steeped in laser industries, kishor padsala embarked on a journey
		to redefine the standards of laser based machinery.</p>
		<p className='fw-bold fs-5' style={{textAlign:"justify"}}>The Genesis of EASY LASER TECH</p>
		<p>Kishor Padsala’s journey began with a singular vision: to revolutionize how
		laser based machine was conceptualized, designed, and manufactured. Armed
		with technical skills and industry experience,He set out to establish EASY
		LASER TECH in 2017. From humble beginnings in Surat, the company quickly
		gained recognition for its commitment to Quality and innovation and
		customization.</p>
		<p className='fw-bold fs-5' style={{textAlign:"justify"}}>Looking Ahead: A Vision for the Future</p>
		<p style={{textAlign:"justify"}}>As EASY LASER TECH navigates the complexities of the modern industrial
		landscape, Kishor Padsala&#39;s vision remains as relevant as ever. With a focus on
		future trends or goals, the company is poised to lead the charge in laser industry
		advancements. Guided by Kishor Padsala&#39;s unwavering commitment to
		innovation and excellence, EASY LASER TECH is not just shaping the future
		of machinery but is actively defining it.</p>
		<p className='fw-bold fs-5'>In Conclution</p>
		<p style={{textAlign:"justify"}}>Kishor Padsala&#39;s journey from visionary entrepreneur to industry trailblazer
		exemplifies the transformative power of passion and expertise. Through his
		leadership, EASY LASER TECH continues to set new benchmarks in laser
		machinery sector, inspiring generations to come. As we celebrate the
		achievements of Kishor Padsala, we also look forward to witnessing the next
		chapter of innovation and growth at EASY LASER TECH, where the future is
		indeed being built today.</p>
		</Container>
		</>
	)
}

export default Owner_Blog
