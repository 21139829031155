import React from 'react'
import { Container,Image } from 'react-bootstrap'
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom';
function GreenSawingCVDSlicing() {
  return (
	<>
	<br/>
	<br/>
	<Container className='text-center my-3'>
	<h2 style={{color:"#199AD8"}} >Green Laser Machine for Sawing, CVD Coring and Slicing

	</h2>
	<Image src='./Images/Product Img/diamond/green 03.png'  style={{height:"100%",width:"50%"}}
	className='my-3 rounded-5'/>
	</Container>
	<Container>
	<p> It is admired the most in the diamond industry for its excellent and consistent high performance. Equipped with direct-drive linear stages(magnetic axis), it achieves the highest level of motion performance. Specifically configured Green Laser System, which is uniquely housed inside the machine to generate a fine-tuned laser beam that is best-suited for Diamond and CVD (Synthetic Diamond) processing. The smart integration of mechatronics, user-friendly software and an advanced operating system delivers considerably superior sawing results. This is India's first machine that is successfully incorporating highly advanced magnetic levitation technology in Diamond Cutting.

	</p>
	
	<h3>General Features :</h3>
												
	<ul class="list-check">

<li>Superior pulse to pulse stability with inbuilt Peak Power Regulation imparts high safety in the cutting process.</li>
									
<li>Field-proven and long-lasting Optical Coating and Laser Diode Module</li>
									
<li>Absence of moving parts in linear motor and linear scale measures position at the load, which results in a high positioning resolution, repeatability & accuracy</li>
									
<li>No contacting parts in linear motion and hence minimum service call over its lifespan</li>
									
<li>Zero backlash as no mechanical transmission components in linear stages</li>
									
<li>No windup, negligible friction and excellent linear motor responsiveness generate outstanding counter accuracy and a super-smooth velocity profile.</li>
									
<li>A Smart interlock system assures the secure running of the machine</li>
									
<li>Thoroughly engineered and precisely sealed Laser Resonator that ensures nil deviation in output power caused due to vibration and change in environmental condition</li>
									
<li>Excellent surface quality throughout the entire depth of cut</li>
									
<li>Motorized direct driven 360⁰ batch production fixture for :  <ul><li>* Precise Single Side or Double Side Sawing </li> <li>* Accurate and precise auto pie sawing from two sides or single side sawing </li> <li>* Smart feature to set multiple sawing planes for cutting an extra pieces in a single opearation</li> <li>* Slicing of CVD with excellent surface finish</li></ul></li>
									
<li>Batch production fixture for very accurate coring of CVD</li>
									
<li>Skin banding option for eliminating the risk of diamond breakage because of internal reflection.</li>
									
<li>A compact and robust frame structure</li>
																					   </ul> 
	</Container>





	<Container className="mt-5 "> 
  <Row className="justify-content-center"> 
	  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	 
	  <Card className='shado'> 
	  <Link to={"/GreenHighValue"} style={{textDecoration:"none"}}>
	    <Card.Img
				  variant="top"
				  src= 
				  './Images/Product Img/diamond/green 04.png' 
				  alt="GeeksforGeeks Logo"
			  /> 
			 
			  <Card.Body> 
			  <h5 className='text-center'>Green Laser Machine for High Valued Performance</h5> 
				 
			  </Card.Body> 
			  </Link>
			  </Card> 
			  
	  </Col> 
	  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		  <Card className='shado'> 
		  <Link to={"/IrGreen4p"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
				  './Images/Product Img/diamond/green 01.png' 
			  /> 
			  <Card.Body > 
				  <h5 className='text-center'>IR/Green Laser Machine for 4P and Faceting operation of Round and Fancy Shapes</h5> 
				
			  </Card.Body>
			  </Link> 
		  </Card> 
	  </Col> 

	  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	  <Card className='shado'> 
	  <Link to={"/GreenSawingShapeCVD"} style={{textDecoration:"none"}}>
		  <Card.Img 
			  variant="top"
			  src= 
			  './Images/Product Img/diamond/green 02.png' 
			  alt="GeeksforGeeks Logo"
		  /> 
		  <Card.Body className='shado'> 
		  <h5 className='text-center'>Green Laser Machine for Sawing ,Shape Cutting, CVD Coring and Slicing</h5> 
			 
		  </Card.Body> 
		  </Link>
	  </Card> 
  </Col> 

  
   
</Row>
</Container>

	</>
  )
}

export default GreenSawingCVDSlicing