import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {Image} from 'react-bootstrap'
import Whatsaapicon from './components/Whatsaapicon'
function DiamondGroovingWheels() {
  return (
	<>
	<Whatsaapicon />
      <br />
      <div
        className="text-center"
        style={{
          backgroundImage: `url("./Images/background/banner weel.webp")`,
          height: "50vh",
          width: "100%",
          backgroundSize: "cover",
        }}
      >

	  <h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
	  Diamond Grooving Wheels
	  </h1>
        <h6 style={{ color: "#ffff" }} className="fw-bold">
		Products /
		<span style={{ color: " #61dafb" }}>
		Diamond Grooving Wheels
		</span>
        </h6>
		</div>
		<br />
		<Container>
		<h1 className="fw-bold mt-3 " style={{ color: "#199AD8" }}>
		Diamond Grooving Wheels
  
		</h1>

<div className="text-center my-3">
		<Image src="./Images/Product Img/Diamond Grooving Wheels 3.jpeg" alt="img" style={{width:"40%"}}/>
     </div>
	</Container>
	<Container>
	<p style={{textAlign:"justify"}} >
	This specialized tool is meticulously designed to meet the complex specifications required for manufacturing heart-shaped diamonds. Its robust construction ensures fast cutting and excellent polishing, making it an indispensable asset in the diamond crafting industry. this diamond grooving tool is an essential instrument for diamond manufacturers, offering unparalleled performance and quality in crafting heart-shaped and other fancy diamonds. Its combination of durability, precision, and versatility makes it a valuable addition to any diamond cutting and polishing toolkit.	</p>
	<p style={{textAlign:"justify"}} >
	Crafting exquisite jewelry requires exceptional craftsmanship, and our diamond grooving wheel is designed to meet the exact needs of this application. It significantly aids in diamond setting, enhancing the quality and security of the jewelry pieces produced.	</p>
	<p style={{textAlign:"justify"}} >
	Our diamond grooving wheel is an essential tool for jewelry manufacturers, designed to meet the precise needs of diamond setting. It supports a variety of patterns and settings, enhances the security of the diamonds, and contributes to superior craftsmanship. Its versatility, efficiency, and ability to provide a secure hold make it indispensable in creating high-quality, exquisite jewelry.	</p>
	</Container>
	<Container>
	<Row className='my-2'>
	<Col xl={6} xs={12}>
	<Image src="./Images/Product Img/01.png" alt="img" style={{width:"40%"}}/>
	</Col>
	<Col xl={6} xs={12} className='mt-5'>
	<h4>- Efficient Angles and Long-Lasting Edge</h4>
	<h4>- High-Standard Quality and Boosted Productivity.</h4>
	<h4>- Experience vibration-free performance, even during high RPM usage.</h4>
	</Col>
	</Row>.
	<Row className='my-2'>
	<Col xl={6} xs={12}>
	<Image src="./Images/Product Img/03.png" alt="img" style={{width:"40%"}}/>
	</Col>
 	<Col xl={6} xs={12} className='mt-5'>
	<h4>- Choose from a range of angles and thickness options, ensuring optimal customization for your needs..</h4>
	<h4>- Also made at your own specifications.</h4>
	</Col>
	</Row>
	<h1 className="fw-bold m-3 " style={{ color: "#199AD8" }}>diagram</h1>
	</Container>
	<Container className='text-center my-3'>
	<Image src='./Images/Product Img/weel d.jpeg' alt='image weel'/>
	</Container>
	<Container>
	<h1 className="fw-bold m-3 "  style={{ color: "#199AD8" }}>Video</h1>
	<iframe width="560" height="315" src="https://www.youtube.com/embed/oyxq2O9M9MU?si=8BuPf_BYteNo6N_S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	</Container>
	</>
  )
}

export default DiamondGroovingWheels
