import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


// important
// first install after working slider function
// npm install react-slick slick-carousel
function SliderProduct() {

	const settings = {
		// dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 7,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1000,
	  };
  return (
	
<>



<Slider {...settings}>

	 
	  <Card className='shado1'> 
	  <Link to={"/flmm"} style={{textDecoration:"none"}}>
	    <Card.Img
				  variant="top"
				  src= 
				  './Images/Product Img/Fiber- Laser-marking-Machine.png' 
				  alt="GeeksforGeeks Logo"
			  /> 
			 
			
			  </Link>
			  </Card> 
			  
	  
		  <Card className='shado1'> 
		  <Link to={"/flcm"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
				  './Images/Product Img/Fiber-Laser-Cutting-Machine.png'				  alt="GeeksforGeeks Logo"
			  /> 
			
			  </Link> 
		  </Card> 
	  

	  <Card className='shado1'> 
	  <Link to={"/flwm"} style={{textDecoration:"none"}}>
		  <Card.Img 
			  variant="top"
			  src= 
'./Images/Product Img/Laser-Welding-Machine.png'
			  alt="GeeksforGeeks Logo"
		  /> 
	
		  </Link>
	  </Card> 
  

  <Card className='shado1'> 
  <Link to={"/olmm"} style={{textDecoration:"none"}}>
	  <Card.Img 
		  variant="top"
		  src= 
		  './Images/Product Img/online laser marking machine.png' 
		  alt="GeeksforGeeks Logo"
	  /> 
	
	  </Link>
  </Card> 

   

  
		  <Card className='shado1'> 
		  <Link to={"/co2lcem"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
				  './Images/Product Img/Co2 Laser Cutting Machine.png' 
				  alt="GeeksforGeeks Logo"
			  /> 
			
			  </Link>
		  </Card> 
	  

		  <Card className='shado1'> 
		  <Link to={"/co2lem"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
				  './Images/Product Img/Co2 Engraving Machine.png' 
				  alt="GeeksforGeeks Logo"
			  /> 
		
			  </Link>
		  </Card> 
	  

	  <Card className='shado1'> 
	  <Link to={"/flmm"} style={{textDecoration:"none"}}>
		  <Card.Img 
			  variant="top"
			  src= 
'./Images/Product Img/UV-Laser-machine.png' 
			  alt="GeeksforGeeks Logo"
		  /> 
		
		  </Link>
	  </Card> 
  

  
<Card className='shado1'> 
<Link to={"/jcm"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
		'./Images/Product Img/Jewellery-Cutting-Machine.png' 
		alt="GeeksforGeeks Logo"
	/> 

	</Link> 
</Card> 

<Card className='shado1'> 
<Link to={"/jsm"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/Product Img/jewellery-laser-soldering-machine.png' 
		alt="GeeksforGeeks Logo"
	/> 

	</Link> 
</Card> 


<Card className='shado1'> 
<Link to={"/DiamondGroovingWheels"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		// style={{height:"20vh"}}
		src= 
'./Images/Product Img/01.png '
		alt=" Logo"
	/> 

	</Link> 
</Card> 


  <Card className='shado1'> 
  <Link to={"/gtm"} style={{textDecoration:"none"}}>
	  <Card.Img 
		  variant="top"
		  src= 
'./Images/Product Img/Gold-Testing-Machine.png' 
		  alt="GeeksforGeeks Logo"
	  /> 
	 
	  </Link>
  </Card> 








  <Link to={"/multisawing"} style={{textDecoration:"none"}}>
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
		'./Images/Product Img/diamond/MULTIPIE SAWING MACHINE 1.jpg' 
		alt="GeeksforGeeks Logo"
	/> 

</Card>
</Link>
<Link to={"/irgelvo"} style={{textDecoration:"none"}}>
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
		'./Images/Product Img/diamond/IR-Gelvo-Fency-4p-1.jpg' 
		alt="GeeksforGeeks Logo"
	/> 
	
</Card> 
</Link>
<Link to={"/fibergelvo"} style={{textDecoration:"none"}}>
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
		'./Images/Product Img/diamond/FIBER-Gelvo-Fency-4p-1.jpg' 
		alt="GeeksforGeeks Logo"
	/> 

</Card> 
</Link>

<Link to={"/cvdcorning"} style={{textDecoration:"none"}}>
<Card className='shad'> 
	<Card.Img 
		variant="top"
		src= 
		'./Images/Product Img/diamond/CVD-Coring-&-face-cut-FIBER-1.jpg' 
		alt="GeeksforGeeks Logo"
	/> 

</Card> 
</Link>





   
	<Card className='shado'> 
	<Link to={"/OptoFiberLaserMarkingMachine"} style={{textDecoration:"none"}}>
	  <Card.Img
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 001 Opto laser marking machine.webp' 
				alt="GeeksforGeeks Logo"
			/> 
		   
		
			</Link>
			</Card> 
			
	

		<Card className='shado'> 
		<Link to={"/ElectroLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 002 Electro laser marking machine.webp'				  alt="GeeksforGeeks Logo"
			/> 
		
			</Link> 
		</Card> 
	

	
	<Card className='shado'> 
	<Link to={"/M360LaserMaekingMachine"} style={{textDecoration:"none"}}>
		<Card.Img 
			variant="top"
			src= 
'./Images/laser marking machine/ELT 003 M360 Degree marking machine.webp'
			alt="GeeksforGeeks Logo"
		/> 
	
		</Link>
	</Card> 



<Card className='shado'> 
<Link to={"/ConvoFlyLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
		'./Images/laser marking machine/ELT 004 Convo fly laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 

	</Link>
</Card> 

 


	
		<Card className='shado'> 
		<Link to={"/MegaLongLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 005 Mega long laser marking machine.webp' 
				alt="GeeksforGeeks Logo"
			/> 
		
			</Link>
		</Card> 
	
	
		<Card className='shado'> 
		<Link to={"/RocksideLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 006 Rockside laser marking machine.webp' 
				alt="GeeksforGeeks Logo"
			/> 
		
			</Link>
		</Card> 
	
	
	<Card className='shado'> 
	<Link to={"/AutoRotaryIndexLaserMarkingMachine"} style={{textDecoration:"none"}}>
		<Card.Img 
			variant="top"
			src= 
'./Images/laser marking machine/ELT 007 Auto rotary index laser marking machine.webp' 
			alt="GeeksforGeeks Logo"
		/> 
	
		</Link>
	</Card> 



 
<Card className='shado'> 
<Link to={"/CloseBodyAutomationLaserMarkingMachine"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
	  './Images/laser marking machine/ELT 008 Close body automation laser marking machine.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
 
  </Link> 
</Card> 

<Card className='shado'> 
<Link to={"/FullFlyerLaserMarkingMachine"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
'./Images/laser marking machine/ELT 009 Full flyer laser marking machine.webp' 
	  alt="GeeksforGeeks Logo"
  /> 

  </Link> 
</Card> 
 


<Card className='shado'> 
<Link to={"/HandheldLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/laser marking machine/ELT 010 Handheld laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 

	</Link>
</Card> 



<Card className='shado'> 
<Link to={"/jcm"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
	  './Images/laser marking machine/ELT 011 Mega laser marking machine for metal.webp' 
	  alt="GeeksforGeeks Logo"
  /> 

  </Link> 
</Card> 

<Card className='shado'> 
<Link to={"/SemiFlyerLaserMarkingMachine"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
'./Images/laser marking machine/ELT 012 Semi flyer laser marking machine.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
 
  </Link> 
</Card> 



<Card className='shado'> 
<Link to={"/OzoneLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/laser marking machine/ELT 013 Ozone laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 

	</Link>
</Card> 



<Card className='shado'> 
<Link to={"/OzoneLaserMarkingMachineTouchScreenModel"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
'./Images/laser marking machine/ELT 014 Ozone laser marking machine Touch screen model.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
  
  </Link> 
</Card> 



<Card className='shado'> 
<Link to={"/RockLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/laser marking machine/ELT 015 Rock laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 
	
	</Link>
</Card> 



{/* Add more images here */}
</Slider>

</>


  )
}

export default SliderProduct