import React from "react";
import Whatsaapicon from "./components/Whatsaapicon";
import Container from "react-bootstrap/esm/Container";
import { Row,Col } from "react-bootstrap";

function Video() {
  document.title = "Video - Easy Laser Tech ";
  return (
    <>
      <Whatsaapicon />

      <br />
      <div
        className="text-center"
        style={{
          backgroundImage: `url("./Images/background/bg-about.jpg")`,
          height: "55vh",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
          OUR PRODUCTS VIDEOS
        </h1>
        <h6 style={{ color: "#ffff" }} className="fw-bold">
          HOME / <span style={{ color: " #61dafb" }}>VIDEOS</span>
        </h6>
      </div>
      <br />

      <Container className="text-center">
        <Row>
          <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/sj6dXpeli4A?si=4rPO9dJLEdxmHKZm"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          
        ></iframe>
        </Col>
        
      
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/hy3yX4bB1WE?si=20laQJv8LabWH5xz&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/WO73BLA0leM?si=RAKvr3Rj_jms5Z3Y&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/Sq259JGNLPs?si=01zW78Ata7ausHgx&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/oSvyJZQf0pI?si=lCKGeYXzl3xdeaYI&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/wV3BvsaIj7o?si=AhNTt9Ud2qP5ypeg&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/1SjDz18CH8Q?si=CbvLu-4KbUEOliDG&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/AwMKeNnpUF4?si=x0ADiXuY1vbyWd1j&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/mBq5y2hq2eY?si=tO3SxxqdWPIP-L_E&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/HWEilPIGsA4?si=2uReV1CfS55DTpXP&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/b1KR9xezODs?si=M07GPyQICNWW8spr&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/DGFL5zjiVD8?si=zKn74kcGDzNvhMPj&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/s0HjKnL_0n0?si=twXQj5nO5On1vQOK&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/6tDqqKioXNE?si=sIXmfcqsSmVGZfYG&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube-nocookie.com/embed/Fbwzdwd7vNY?si=BR13xGM50eKzHLsV&amp;start=4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        <Col xl={4} xm={6} xs={12}>
        <iframe
          className="mx-1"
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/oyxq2O9M9MU?si=39V73dbsGKdezT5h"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        </Col>
        </Row>
      </Container>
    </>
  );
}

export default Video;
