import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { Dropdown, NavDropdown } from "react-bootstrap";
import { useState } from "react";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggle = (isOpen) => {
    // console.log("check")
    setDropdownOpen(isOpen);
    // if (!isOpen) {
    //   handleDropdownClose();
    
    // }         
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false)
  }



  return (
    <>
      <Navbar
        expand="lg"
        className="bg-white fixed-top"
        style={{
          boxShadow:
            "0 1px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.15)",
            
        }}
      >
        <Container>
          <Image src="./Images/ELT-logo-H.jpeg" style={{ width: "11rem" }} />
          <div className="menu-bg rounded-3 border border-info " >
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="shadow-none border-0 "  />
          </div>
		  
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto  my-lg-0" >
              <Nav.Link
                as={NavLink}
                activeClassName="active"
                 to="/"
                className="fs-5 fw-medium mx-2 animate"
                onClick={()=>{ handleDropdownClose(); window.location.href="/"}}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                activeClassName="active"
                 to="/about"
                className="fs-5 fw-medium mx-2"
                onClick={()=>{ handleDropdownClose(); window.location.href="/about"}}
              >
                About
              </Nav.Link>

              <NavDropdown
			  
                title="Laser Marking Machine"
                as={NavLink}
                // to="/products"
                id="basic-nav-dropdown"
                onToggle={handleToggle}
				show={dropdownOpen}
				
                className="fs-5 fw-medium mx-2 text-decoration-none"
              >
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  // to="/OzoneLaserMarkingMachine"
                  className=" fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/OzoneLaserMarkingMachine"}}
                >
                  Ozone Laser Marking Machine
                </NavDropdown.Item>

                <Dropdown />
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/OzoneLaserMarkingMachineTouchScreenModel"
                  className=" fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/OzoneLaserMarkingMachineTouchScreenModel"}}

                >
                  Ozone Laser Marking Machine Touch Screen Model
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/OptoFiberLaserMarkingMachine"
                  className=" fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/OptoFiberLaserMarkingMachine"}}

                >
                  Opto Fiber Laser Marking Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/MegaLaserMarkingMachineforMetal"
                  className="fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/MegaLaserMarkingMachineforMetal"}}

                >
                  Mega Laser Marking Machine for Metal
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/RockLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/RockLaserMarkingMachine"}}

                >
                  Rock Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/ElectroLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/ElectroLaserMarkingMachine"}}

                >
                  Electro Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/RocksideLaserMarkingMachine"
                  className="fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/RocksideLaserMarkingMachine"}}

                >
                  Rockside Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/M360LaserMaekingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/M360LaserMaekingMachine"}}

                >
                  M360° Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/HandheldLaserMarkingMachine"
                  className="fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/HandheldLaserMarkingMachine"}}

                >
                  Handheld Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/SemiFlyerLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/SemiFlyerLaserMarkingMachine"}}

                >
                  Semi Flyer Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/AutoRotaryIndexLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/AutoRotaryIndexLaserMarkingMachine"}}

                >
                  Auto Rotary Index Laser Marking Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/FullFlyerLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/FullFlyerLaserMarkingMachine"}}

                >
                  Full Flyer Laser Marking Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/CloseBodyAutomationLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/CloseBodyAutomationLaserMarkingMachine"}}

                >
                  Close Body Automation Laser Marking Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/ConvoFlyLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/ConvoFlyLaserMarkingMachine"}}

                >
                  Convo Fly Laser Marking Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/MegaLongLaserMarkingMachine"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/MegaLongLaserMarkingMachine"}}
                >
                  Mega Long Laser Marking Machine
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Products"
                as={NavLink}
                to="/products"
                id="basic-nav-dropdown"
                className="fs-5 fw-medium mx-2 text-decoration-none"
              >
                <span className=" fs-4 card text-center">Our Products</span>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/DiamondGroovingWheels"
                  className=" fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/DiamondGroovingWheels"}}
                >
                  Diamond Grooving Wheels
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/flmm"
                  className=" fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/flmm"}}
                >
                  Fiber Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/flcm"
                  className=" fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/flcm"}}
                >
                  Fiber Laser Cutting Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/flwm"
                  className=" fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/flwm"}}
                >
                  Fiber Laser Welding Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/olmm"
                  className="fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/olmm"}}
                >
                  Online Laser Marking Machine
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/co2lcem"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/co2lcem"}}
                >
                  Co2 Laser Cutting & Engraving Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/co2lem"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/co2lem"}}
                >
                  Co2 Laser Engraving Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/uvlmm"
                  className="fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/uvlmm"}}
                >
                  UV Laser Marking Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/gtm"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/gtm"}}
                >
                  Gold Testing Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/jcm"
                  className="fw-medium bg-white text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/jcm"}}
                >
                  Jewellery Cutting Machine
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/jsm"
                  className="fw-medium bg-light text-black"
                  onClick={()=>{ handleDropdownClose(); window.location.href="/jsm"}}
                >
                  Jewellery Soldering Machine
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                as={NavLink}
                activeClassName="active"
                to="/gallery"
                className="fs-5 fw-medium mx-2 animate"
                onClick={()=>{ handleDropdownClose(); window.location.href="/gallery"}}
              >
                Gallery
              </Nav.Link>

              <Nav.Link
                as={NavLink}
                activeClassName="active"
                to="/blog"
                className="fs-5 fw-medium mx-2 animate"
                onClick={()=>{ handleDropdownClose(); window.location.href="/blog"}}
              >
                Blog
              </Nav.Link>

              <Nav.Link
                as={NavLink}
                activeClassName="active"
                to="/video"
                className="fs-5 fw-medium mx-2"
                onClick={()=>{ handleDropdownClose(); window.location.href="/video"}}
              >
                Videos
              </Nav.Link>

              <Nav.Link
                as={NavLink}
                activeClassName="active"
                to="/contact"
                className="fs-5 fw-medium mx-2"
                onClick={()=>{ handleDropdownClose(); window.location.href="/contact"}}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
      <br />
      <br />
    </>
  );
};

export default Header;
