import React from 'react'
import {Image} from 'react-bootstrap'
import Whatsaapicon from './components/Whatsaapicon'
import {Container} from 'react-bootstrap'
const CvdCorning = () => {
	document.title = " CVD Coring and Facecut Fiber - Easy Laser Tech ";

  return (
	<>
	<Whatsaapicon />
      <br />
      <div
        className="text-center"
        style={{
          backgroundImage: `url("./Images/background/about-bg.webp")`,
          height: "50vh",
          width: "100%",
          backgroundSize: "cover",
        }}
      >

	  <h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
	  CVD Coring and Facecut Fiber
	  </h1>
        <h6 style={{ color: "#ffff" }} className="fw-bold">
		Products /
		<span style={{ color: " #61dafb" }}>
		CVD Coring and Facecut Fiber
		</span>
        </h6>
		</div>
		<br />
		<Container>
		<h1 className="fw-bold mt-3 " style={{ color: "#199AD8" }}>
		CVD Coring and Facecut Fiber
  
		</h1>

<div className="text-center my-3">
		<Image src="./Images/Product Img/diamond2/cvdCaring.jpeg" alt="img" style={{width:"40%"}}/>
     </div>
	</Container>
	<Container className='my-3'>
		<h2>Specification</h2>
		<p>- coring cutting of any shape</p>
		<p>- easy operating sysytem</p>
		<p>- With good accuracy of shape
		</p>
		<p>- maximum productions</p>
		<p>- fully auto-software based</p>
		<p>- smooth cutting surface</p>
	</Container>
	</>
  )
}

export default CvdCorning
