import React from 'react'
import {Image} from 'react-bootstrap'
import Whatsaapicon from './components/Whatsaapicon'
import {Container} from 'react-bootstrap'
const IrGelvo = () => {
	document.title = "IR Galvo Fancy 4p Machine - Easy Laser Tech ";
  return (
	<>
	<Whatsaapicon />
      <br />
      <div
        className="text-center"
        style={{
          backgroundImage: `url("./Images/background/about-bg.webp")`,
          height: "50vh",
          width: "100%",
          backgroundSize: "cover",
        }}
      >

	  <h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
	  IR Galvo Fancy 4p Machine
	  </h1>
        <h6 style={{ color: "#ffff" }} className="fw-bold">
		Home /
		<span style={{ color: " #61dafb" }}>
		IR Galvo Fancy 4p Machine
		</span>
        </h6>
		</div>
		<br />
		<Container>
		<h1 className="fw-bold mt-3 " style={{ color: "#199AD8" }}>
		IR Galvo Fancy 4p Machine
  
		</h1>

<div className="text-center my-3">
		<Image src="./Images/Product Img/diamond2/irGalvo.jpeg" alt="img" style={{width:"40%"}}/>
     </div>
	</Container>
	<Container className='mt-3'>
	<h2>Specifications</h2>
		<p>- Process of  32 different shape cutting</p>
		<p>- Easy operating system</p>
		<p>- Good Production capacity</p>
		<p>- minuimum brecking ratio</p>
	</Container>
	</>
  )
}

export default IrGelvo
