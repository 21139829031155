import React from 'react'
import { Container} from 'react-bootstrap';
import {Image} from "react-bootstrap";
import Whatsaapicon from '../components/Whatsaapicon';
const LMDI_BLOG5 = () => {
	document.title = "APPLICATION OF LASER MARKING IN DIFFERENT INDUSTRIES-Blog || Easy Laser Tech ";
  return (
	<>
	  
		<div className='bg-light'>
  <br/>
  <Whatsaapicon/>
  
  
	  <div className='text-center' style={{backgroundImage:`url("./Images/background/about-bg.webp")`, height:"60vh",width:"100%",backgroundSize:"cover"}}>
	  <h2 className=' fw-bold' style={{color:"#ffff",paddingTop:"105px"}}>APPLICATION OF LASER MARKING IN DIFFERENT INDUSTRIES</h2>
	  <h6 style={{color:"#ffff"}} className="fw-bold">BLOG / <span style={{color:" #61dafb"}}>APPLICATION OF LASER MARKING IN DIFFERENT INDUSTRIES</span></h6>
  </div>
	  <br/>
	  </div>
	  <Container>
	  <h2 className="text-center my-4 fw-bold" style={{color:"#199AD8"}} > APPLICATION OF LASER MARKING IN DIFFERENT INDUSTRIES</h2>
	  <Image src="./Images/blog/blog5.jpeg" alt="blog" style={{width:"50%",height:"100%"}}/> 
	   </Container>
	   <Container>
	   <p className='mt-3' style={{textAlign:"justify"}}> <span className='fw-bold'>1) Automotive: </span>Laser marking is used for part identification, serial numbers, logos, and
	   barcodes on automotive components. It ensures traceability and withstands harsh
	   environments.  </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>2) Medical Devices: </span>  Laser marking is crucial for marking medical instruments and
	   implants with lot numbers, expiration dates, and manufacturer information. It&#39;s
	   essential for traceability and compliance with regulatory standards. </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>3) Electronics: </span> Electronics manufacturers use laser marking for labelling circuit boards,
	   connectors, and other components with serial numbers, logos, and barcodes. Laser
	   marking is precise and doesn&#39;t damage sensitive electronic parts.  </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>4) Aerospace: </span> Laser marking is utilized in aerospace for part identification, including
	   serial numbers, part numbers, and compliance markings. It ensures parts can be traced
	   throughout their lifecycle.  </p>
	   <p style={{textAlign:"justify"}} > <span className='fw-bold'>5) Jewellery: </span>  Jewellery makers use laser marking for engraving logos, designs, and even
	   personal messages on precious metals and gemstones. Laser marking offers high
	   precision and customization. </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'> 6) Packaging:</span>  Laser marking is employed in packaging industries for marking expiry
	   dates, batch numbers, and barcodes on packaging materials such as plastics, foils, and
	   cardboard. </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>7) Tooling and Metalworking: </span> Laser marking is used for tool identification, branding,
	   and traceability in metalworking and tool manufacturing industries. It provides
	   durable marking without affecting the material&#39;s integrity.  </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>8) Military and Defence: </span>  Laser marking is crucial in military and defence applications
	   for marking equipment, firearms, and munitions with serial numbers, identifiers, and
	   compliance markings. </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>9) Consumer Goods: </span> Laser marking is used on consumer products for branding,
	   labeling, and serialization. It&#39;s common in industries such as appliances, sporting
	   goods, and personal electronics.  </p>
	   <p style={{textAlign:"justify"}}> <span className='fw-bold'>10) Textiles and Apparel: </span> Laser marking is employed in textile industries for marking
	   fabric labels, logos, and design elements without damaging the material.  </p>
	   </Container>
	</>
  )
}

export default LMDI_BLOG5
