import React, { useState, useEffect } from "react";
import "./PopupForm.css"; // Create a CSS file for styling
import axios from "axios";
import { Row, Col, Image } from "react-bootstrap";

function PopupForm() {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email:"",
    city:"",
    requirement: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.post(
         "https://formspree.io/f/mjvnonpl", // Replace with your Formspree form ID
       
      //  " https://formspree.io/f/mvoynggv", Ravi api
        formData,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Form submission successful:", response.data);
      // setSuccess(true);
      setIsVisible(false);
    } catch (error) {
      console.error("Form submission error:", error);
      // setError(error.message);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  // Automatically show the popup after a certain time (e.g., 5 seconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 6000); // Show popup after 6 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="d-flex justify-content-between">
          <h2 style={{ color: "#217AE9" }}> Inquiry Now </h2>
          <button className="px-2" onClick={handleClose}>
            X
          </button>
        </div>
        
        <Row>
          <Col className=" rounded" xs={4}>
            <div className="text-center">
              <Image
                src="./Images/ELT-logo-H.jpeg"
                style={{ width: "9rem" }}
                className="mt-5 img-fluid"
              />
            </div>
            <p className=" mt-5 text-justify">+91 6353399691</p>
          </Col>
          <Col xs={8}>
            <form
              onSubmit={handleSubmit}
              method="POST"
              className="col-12 container text-center "
              target="_black"
            >
              <div className="form-group mt-1">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  onChange={handleChange}
                  value={formData.name}
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  className="form-control "
                  placeholder="Gmail"
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="city"
                  className="form-control "
                  placeholder="City"
                  onChange={handleChange}
                  value={formData.city}
                  required
                />
              </div>

              <div className="form-group">
                <input
                  type="number"
                  name="number"
                  className="form-control"
                  placeholder="Mobile Number"
                  onChange={handleChange}
                  value={formData.number}
                  required
                />
              </div>

              <div className="form-group mb-4">
                <textarea
                  className="form-control rounded-1"
                  name="requirement"
                  onChange={handleChange}
                  value={formData.requirement}
                  id="Textarea2"
                  rows="3"
                  placeholder="Requirement"
                  autocomplete="off"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="px-5 rounded-1 p-1 "
                style={{
                  background: "#0099DA",
                  color: "white",
                  border: "none",
                }}
              >
                Submit
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PopupForm;
