import React from 'react'
import { Col, Container } from 'react-bootstrap'
import { Row } from "react-bootstrap";
import Image from 'react-bootstrap/esm/Image'
import Whatsaapicon from './components/Whatsaapicon';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollButton from './components/ScrollButton';



function FLMM() {
	document.title = "Fiber Laser Marking Machine ";
	
  return (
	  <>


	  <Whatsaapicon/>
	  <ScrollButton/>
	  
	<br/>
	<div className='text-center' style={{backgroundImage:`url("./Images/background/all-bg.png")`, height:"50vh",width:"100%",backgroundSize:"cover"}}>
	<h1 className=' fw-bold' style={{color:"#ffff",paddingTop:"86px"}}>
	FIBER LASER MARKING MACHINE</h1>
	<h6 style={{color:"#ffff" }} className="fw-bold">HOME / <span style={{color:" #61dafb"}}>
	FIBER LASER MARKING MACHINE</span></h6>
</div>
	<br/>
<br/>
	<Container>
	<Row style={{borderBottom:"2px solid #AED6F1 "}}>
	<Col xl={6} xs={12} >
	<Image src= './Images/Product Img/Fiber- Laser-marking-Machine.png' style={{width:"100%"}}/>
	
	</Col>


<Col xl={6} xs={12} style={{borderLeft:"2px solid #AED6F1 "}}>
  <h1 className='fw-bold mt-3' style={{color:"#199AD8"}}>Fiber Laser Marking Machine</h1>
  <hr/>
	<h4 className='my-3'>Specification </h4>
	<div className="table-responsive mb-3 ">
	<table className="table table-bordered table-striped table-hover producttable">
		<tbody>
			<tr>
				<th>Working Area</th>
				<td>100x100, 175x175, 250x250, 300x300 MM & Customized</td>
			</tr>
			<tr>
				<th>Laser Type</th>
				<td>Fiber Laser</td>
			</tr>
			<tr>
				<th>Laser Power</th>
				<td>20, 30, 50, 60, 100 W</td>
			</tr>
			<tr>
				<th>Operating System</th>
				<td>Win 7 / 8 / 10 / 11</td>
			</tr>
			<tr>
				<th>Supported File</th>
				<td>PLT, DXF, BMP, JPG, GLF, PNG.</td>
			</tr>
			<tr>
				<th>Working Volt</th>
				<td>Single Phase 220-230V / 50HZ (Earthing Req)</td>
			</tr>
		</tbody>
	</table>
	</div>
	<h4 className='my-3'>Application</h4>
	<Image src="./Images/Application-img/application.png" style={{ width: "100%" }} />

</Col>
</Row>
</Container>
<Container className='my-3 text-center'>
<iframe className='mx-2' width="300" height="200" src="https://www.youtube-nocookie.com/embed/1SjDz18CH8Q?si=CbvLu-4KbUEOliDG&amp;start=4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
<iframe className='mx-2' width="300" height="200" src="https://www.youtube.com/embed/AwMKeNnpUF4?si=x0ADiXuY1vbyWd1j&amp;start=4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
<iframe className='mx-2' width="300" height="200" src="https://www.youtube-nocookie.com/embed/DGFL5zjiVD8?si=zKn74kcGDzNvhMPj&amp;start=4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
<iframe className='mx-2' width="300" height="200" src="https://www.youtube-nocookie.com/embed/s0HjKnL_0n0?si=twXQj5nO5On1vQOK&amp;start=4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</Container>
<br/>
<Container>
<h1 className='fw-bold mt-3 center' style={{color:"#199AD8"}}>See More Laser Marking Machines
</h1>
</Container>

<Container className="mt-5 "> 
<Row className="justify-content-center"> 
	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
   
	<Card className='shado'> 
	<Link to={"/OptoFiberLaserMarkingMachine"} style={{textDecoration:"none"}}>
	  <Card.Img
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 001 Opto laser marking machine.webp' 
				alt="GeeksforGeeks Logo"
			/> 
		   
			<Card.Body> 
			<h4 className='text-center'> Opto laser marking machine</h4> 
			   
			</Card.Body> 
			</Link>
			</Card> 
			
	</Col> 
	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		<Card className='shado'> 
		<Link to={"/ElectroLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 002 Electro laser marking machine.webp'				  alt="GeeksforGeeks Logo"
			/> 
			<Card.Body > 
				<h4 className='text-center'>Electro laser marking machine</h4> 
			  
			</Card.Body>
			</Link> 
		</Card> 
	</Col> 

	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	<Card className='shado'> 
	<Link to={"/M360LaserMaekingMachine"} style={{textDecoration:"none"}}>
		<Card.Img 
			variant="top"
			src= 
'./Images/laser marking machine/ELT 003 M360 Degree marking machine.webp'
			alt="GeeksforGeeks Logo"
		/> 
		<Card.Body className='shado'> 
		<h4 className='text-center'>M360 Degree marking machine</h4> 
		   
		</Card.Body> 
		</Link>
	</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/ConvoFlyLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
		'./Images/laser marking machine/ELT 004 Convo fly laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body> 
	<h4 className='text-center'>Convo fly laser marking machine</h4> 
	   
	</Card.Body> 
	</Link>
</Card> 
</Col> 
 


	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		<Card className='shado'> 
		<Link to={"/MegaLongLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 005 Mega long laser marking machine.webp' 
				alt="GeeksforGeeks Logo"
			/> 
			<Card.Body> 
			<h4 className='text-center'>Mega Long Laser Marking Machine</h4> 
			   
			</Card.Body> 
			</Link>
		</Card> 
	</Col> 
	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		<Card className='shado'> 
		<Link to={"/RocksideLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/laser marking machine/ELT 006 Rockside laser marking machine.webp' 
				alt="GeeksforGeeks Logo"
			/> 
			<Card.Body> 
			<h4 className='text-center'>Rockside laser marking machine</h4> 
			  
			</Card.Body> 
			</Link>
		</Card> 
	</Col> 

	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	<Card className='shado'> 
	<Link to={"/AutoRotaryIndexLaserMarkingMachine"} style={{textDecoration:"none"}}>
		<Card.Img 
			variant="top"
			src= 
'./Images/laser marking machine/ELT 007 Auto rotary index laser marking machine.webp' 
			alt="GeeksforGeeks Logo"
		/> 
		<Card.Body> 
		<h4 className='text-center'>Auto rotary index laser marking machine</h4> 
		   
		</Card.Body> 
		</Link>
	</Card> 
</Col> 


<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/CloseBodyAutomationLaserMarkingMachine"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
	  './Images/laser marking machine/ELT 008 Close body automation laser marking machine.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
  <Card.Body> 
  <h4 className='text-center'>Close body automation laser marking machine</h4> 
	 
  </Card.Body>
  </Link> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/FullFlyerLaserMarkingMachine"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
'./Images/laser marking machine/ELT 009 Full flyer laser marking machine.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
  <Card.Body> 
  <h4 className='text-center'>Full flyer laser marking machine</h4> 
	 
  </Card.Body>
  </Link> 
</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/HandheldLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/laser marking machine/ELT 010 Handheld laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body> 
	<h4 className='text-center my-3'>Handheld laser marking machine</h4> 
	   
	</Card.Body> 
	</Link>
</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/jcm"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
	  './Images/laser marking machine/ELT 011 Mega laser marking machine for metal.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
  <Card.Body> 
  <h4 className='text-center'>Mega laser marking machine for metal</h4> 
	 
  </Card.Body>
  </Link> 
</Card> 
</Col> 
<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/SemiFlyerLaserMarkingMachine"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
'./Images/laser marking machine/ELT 012 Semi flyer laser marking machine.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
  <Card.Body> 
  <h4 className='text-center'>Semi flyer laser marking machine</h4> 
	 
  </Card.Body>
  </Link> 
</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/OzoneLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/laser marking machine/ELT 013 Ozone laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body> 
	<h4 className='text-center my-3'>Ozone laser marking machine</h4> 
	    
	</Card.Body> 
	</Link>
</Card> 
</Col> 


<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/OzoneLaserMarkingMachineTouchScreenModel"} style={{textDecoration:"none"}}>
  <Card.Img 
	  variant="top"
	  src= 
'./Images/laser marking machine/ELT 014 Ozone laser marking machine Touch screen model.webp' 
	  alt="GeeksforGeeks Logo"
  /> 
  <Card.Body> 
  <h4 className='text-center'>Ozone laser marking machine Touch screen model</h4> 
	 
  </Card.Body>
  </Link> 
</Card> 
</Col> 

<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
<Card className='shado'> 
<Link to={"/RockLaserMarkingMachine"} style={{textDecoration:"none"}}>
	<Card.Img 
		variant="top"
		src= 
'./Images/laser marking machine/ELT 015 Rock laser marking machine.webp' 
		alt="GeeksforGeeks Logo"
	/> 
	<Card.Body> 
	<h4 className='text-center my-3'> Rock laser marking machine </h4>
	   
	</Card.Body> 
	</Link>
</Card> 
</Col> 

</Row> 
</Container> 

						
	  </>
  )
}


export default FLMM
