import React from "react";
import { Container, Image } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
function IrGreen4p() {
  return (
    <>
      <br />
      <br />
      <Container className='text-center'>
        <h2 className="text-center my-4" style={{color:"#199AD8"}} >
          IR/Green Laser Machine for 4P and Faceting operation of Round and
          Fancy Shapes
        </h2>
        <Image
          src="./Images/Product Img/diamond/green 01.png"
          style={{ height: "100%", width: "50%" }}
        />
		</Container>
		<Container>
        <p>
          IR/Green laser Machine suitable for 4P (Table Sawing with Bruiting of
          Crown, Girdle and Pavilion) and Faceting operations of Diamonds in all
          existing & popular 36 shapes of Fancy and Round Diamonds. Smart
          integration of excellent CNC motion system and advanced operating
          software allows to set machines for cutting any Customized Shape of
          the stone. It is specifically designed for delivering pristine results
          at affordable pricing.IR Laser machine in diamond industry for high
          quality performance in 4P operations of smaller size (around 1 cent)
          to bigger size goods (50 carats)
        </p>

        <h3>General features and benefits :</h3>

        <ul class="list-check">
          <li>
            Superior pulse to pulse stability, with inbuilt Peak Power
            Regulation, imparts high safety in cutting process.
          </li>

          <li>
            Field proven long lasting Optical Coating and Laser Diode Module
          </li>

          <li>
            Smart Interlock System ensures safe and smooth running of the
            machine
          </li>

          <li>
            Highly engineered and precisely sealed Laser Resonator, that ensures
            nil deviation in output power, caused due to vibration and change in
            environmental condition
          </li>

          <li>
            High Precision mechatronics of 4P fixture imparts accurate shaping
            of stones as per the pre- set geometry parameters and ensures
            consistence performance, without the need of frequent calibration
          </li>

          <li>
            High quality, long life and maintenance free Motion System Excellent
          </li>

          <li>surface quality throughout the depth of the cut</li>

          <li>
            Skin Banding option for eliminating the risk of diamond breakage
            because of internal reflection
          </li>

          <li>Compact and robust Frame Structure</li>
        </ul>
      </Container>


	  
	<Container className="mt-5 "> 
	<Row className="justify-content-center"> 
		<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	   
		<Card className='shado'> 
		<Link to={"/GreenHighValue"} style={{textDecoration:"none"}}>
		  <Card.Img
					variant="top"
					src= 
					'./Images/Product Img/diamond/green 04.png' 
					alt="GeeksforGeeks Logo"
				/> 
			   
				<Card.Body> 
				<h5 className='text-center'>Green Laser Machine for High Valued Performance</h5> 
				   
				</Card.Body> 
				</Link>
				</Card> 
				
		</Col> 
		
  
		<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		<Card className='shado'> 
		<Link to={"/GreenSawingShapeCVD"} style={{textDecoration:"none"}}>
			<Card.Img 
				variant="top"
				src= 
				'./Images/Product Img/diamond/green 02.png' 
				alt="GeeksforGeeks Logo"
			/> 
			<Card.Body className='shado'> 
			<h5 className='text-center'>Green Laser Machine for Sawing ,Shape Cutting, CVD Coring and Slicing</h5> 
			   
			</Card.Body> 
			</Link>
		</Card> 
	</Col> 
  
	<Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	<Card className='shado'> 
	<Link to={"/GreenSawingCVDSlicing"} style={{textDecoration:"none"}}>
		<Card.Img 
			variant="top"
			src= 
			'./Images/Product Img/diamond/green 03.png' 
			alt="GeeksforGeeks Logo"
		/> 
		<Card.Body> 
		<h5 className='text-center'>Green Laser Machine for Sawing, CVD Coring and Slicing</h5> 
		   
		</Card.Body> 
		</Link>
	</Card> 
  </Col> 
	 
  </Row>
  </Container>
    </>
  );
}

export default IrGreen4p;
