import React from 'react'
import Whatsaapicon from "./components/Whatsaapicon";
import { Container } from "react-bootstrap";
import {Image} from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom';
const OzoneLaserMarkingMachineTouchScreenModel = () => {
  return (
	

	<>
	<Whatsaapicon />
	<br />
	<div
	  className="text-center"
	  style={{
		backgroundImage: `url("./Images/marking-bg.jpg")`,
		height: "50vh",
		width: "100%",
		backgroundSize: "cover",
	  }}
	>

	<h1 className=" fw-bold" style={{ color: "#ffff", paddingTop: "86px" }}>
	Ozone Laser Marking Machine Touch Screen Model
	</h1>
	  <h6 style={{ color: "#ffff" }} className="fw-bold">
	  Laser Marking Machine /
	  <span style={{ color: " #61dafb" }}>
	  Ozone Laser Marking Machine Touch Screen Model
	  </span>
	  </h6>
	  </div>
	  <br />
	  <Container>
	  <h1 className="fw-bold mt-3 " style={{ color: "#199AD8" }}>
	  Ozone Laser Marking Machine Touch Screen Model

	  </h1>

<div className="text-center my-3">
	  <Image src="./Images/laser marking machine/ELT 014 Ozone laser marking machine Touch screen model.webp" alt="img" style={{width:"40%"}}/>
   </div>
  
	<h3 className="my-3" style={{ color: "#199AD8" }}>Specification </h3>

	<div className="table-responsive mb-3">
	  <table className="table table-bordered table-striped table-hover producttable">
		<tbody>
		  <tr>
			<th>Specification</th>
			<th>Value</th>
		  </tr>
		  <tr>
			<td>Laser Type </td>
			<td>Pulsed Fiber Laser</td>
		  </tr>
		  <tr>
			<td>Average Output Power</td>
			<td>20 W (Optional 30/50/80/100 W)</td>
		  </tr>
		  <tr>
			<td>Wavelength </td>
			<td>1064 nm</td>
		  </tr>
		  <tr>
			<td>Output Power Stability (Typical) </td>
			<td>5%</td>
		  </tr>
		  <tr>
			<td>Power Adjusting Range </td>
			<td>1 ~ 100 %</td>
		  </tr>
		  <tr>
			<td>Resolution Frequency </td>
			<td>20-500 KHz (Optional)</td>
		  </tr>
		  <tr>
			<td>Beam Quality (M²) </td>
			<td><i class="fa fa-angle-left" aria-hidden="true"></i>1.6</td>
		  </tr>

		  <tr>
			<td>Output Beam Diameter (1/e2) </td>
			<td> 6-9 mm</td>
		  </tr>
		  <tr>
			<td>Max. Linear Speed </td>
			<td> Max 12000 mm/s</td>
		  </tr>
		  <tr>
			<td>Marking Depth </td>
			<td> 0.01~0.3 mm (Material Depended)</td>
		  </tr>
		  <tr>
			<td>Work Area</td>
			<td> 110mm x 110mm (Optional)</td>
		  </tr>
		  <tr>
			<td>Min. Character Height </td>
			<td> 0.2 mm</td>
		  </tr>
		  <tr>
			<td>Power Supply Single Phase </td>
			<td> 220V / 50Hz</td>
		  </tr>
		  <tr>
			<td>Power Consumption </td>
			<td> ~ 500 W</td>
		  </tr>
		  <tr>
			<td>Ambient Temperature Range </td>
			<td> 0° to 25° C</td>
		  </tr>
		  <tr>
			<td>Cooling System </td>
			<td>Air Cooling System</td>
		  </tr>
		</tbody>
	  </table>
	</div>



	<h3 className="my-3" style={{ color: "#199AD8" }}>Features </h3>

	<div className="table-responsive mb-3">
	  <table className="table table-bordered table-striped table-hover producttable">
		<tbody>
		<tr>
	   <th>Features</th> 
		</tr>
		  <tr>
			<td>Portable machine design with compact size
			</td>
			
		  </tr>
		  <tr>
			<td>Production Report Integration
			</td>
			
		  </tr>
		  <tr>
			<td>Status monitoring and safe shutdown
			</td>
			
		  </tr>
		  <tr>
			
			<td>Circumferential Markings (Mark Round Parts)
			</td>
		  </tr>
		  <tr>
			
			<td>Maintenance Free with low power consumption (~ 500 W)
			</td>
		  </tr>
		  <tr>
			
			<td>Bar-coding, 2D Data Matrix Codes, Lot Codes, Date Codes
			</td>
		  </tr>
		  <tr>
			
			<td>Real-time red beam preview ensures accurate positioning
			</td>
		  </tr>
		  <tr>
			
			<td> Compatible with Surface Marking, Engraving, and Texturing
			</td>
		  </tr>

		  <tr>
			
			<td> Support customized software and automation integration
			</td>
		  </tr>
		  <tr>
			<td>Scribing operations, Surface Annealing, and Etching
			</td>
			
		  </tr>
		  <tr>
			<td>Marking Alphanumerics, Serial Numbers, and Part Numbers
			</td>
			
		  </tr>
		  <tr>
			<td>Marking of Logos, Schematics, Complex Graphics (PLT, SVG, DXF, DWG), and Pictures (BMP, JPEG, PNG)
			</td>
			
		  </tr>
		  <tr>
			
			<td> Auto Marking functionality, Boundary & Round Preview
			</td>
		  </tr>
		  
		</tbody>
	  </table>
	  </div>
	  </Container>


	  <br/>
	  <Container>
	  <h2 className='fw-bold mt-3 center' style={{color:"#199AD8"}}>Related Products
	  </h2>
	  </Container>
	  
	  <div className="mt-5 "> 
	  <Row className="justify-content-center"> 
		  <Col xs={12} md={6} lg={2} className="my-1 shado1"> 
		 
		  <Card className='shado'> 
		  <Link to={"/OptoFiberLaserMarkingMachine"} style={{textDecoration:"none"}}>
			<Card.Img
					  variant="top"
					  src= 
					  './Images/laser marking machine/ELT 001 Opto laser marking machine.webp' 
					  alt="GeeksforGeeks Logo"
				  /> 
				 
				  <Card.Body> 
				  <h4 className='text-center'> Opto laser marking machine</h4> 
					 
				  </Card.Body> 
				  </Link>
				  </Card> 
				  
		  </Col> 
		  <Col xs={12} md={6} lg={2} className="my-1 shado1"> 
			  <Card className='shado'> 
			  <Link to={"/ElectroLaserMarkingMachine"} style={{textDecoration:"none"}}>
				  <Card.Img 
					  variant="top"
					  src= 
					  './Images/laser marking machine/ELT 002 Electro laser marking machine.webp'				  alt="GeeksforGeeks Logo"
				  /> 
				  <Card.Body > 
					  <h4 className='text-center'>Electro laser marking machine</h4> 
					
				  </Card.Body>
				  </Link> 
			  </Card> 
		  </Col> 
	  
		  <Col xs={12} md={6} lg={2} className="my-1 shado1"> 
		  <Card className='shado'> 
		  <Link to={"/M360LaserMaekingMachine"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
	  './Images/laser marking machine/ELT 003 M360 Degree marking machine.webp'
				  alt="GeeksforGeeks Logo"
			  /> 
			  <Card.Body className='shado'> 
			  <h4 className='text-center'>M360 Degree marking machine</h4> 
				 
			  </Card.Body> 
			  </Link>
		  </Card> 
	  </Col> 
	  
	  <Col xs={12} md={6} lg={2} className="my-1 shado1"> 
	  <Card className='shado'> 
	  <Link to={"/ConvoFlyLaserMarkingMachine"} style={{textDecoration:"none"}}>
		  <Card.Img 
			  variant="top"
			  src= 
			  './Images/laser marking machine/ELT 004 Convo fly laser marking machine.webp' 
			  alt="GeeksforGeeks Logo"
		  /> 
		  <Card.Body> 
		  <h4 className='text-center'>Convo fly laser marking machine</h4> 
			 
		  </Card.Body> 
		  </Link>
	  </Card> 
	  </Col> 
	   
	  
	  
		  <Col xs={12} md={6} lg={2} className="my-1 shado1"> 
			  <Card className='shado'> 
			  <Link to={"/MegaLongLaserMarkingMachine"} style={{textDecoration:"none"}}>
				  <Card.Img 
					  variant="top"
					  src= 
					  './Images/laser marking machine/ELT 005 Mega long laser marking machine.webp' 
					  alt="GeeksforGeeks Logo"
				  /> 
				  <Card.Body> 
				  <h4 className='text-center'>Mega Long Laser Marking Machine</h4> 
					 
				  </Card.Body> 
				  </Link>
			  </Card> 
		  </Col> 
	  
		  <Col xs={12} md={6} lg={2} className="my-1 "> 
		  <Card > 
		  <Link  to="/flmm" style={{textDecoration:"none"}}>
		  
			  <Card.Body> 
			  <h4 className='text-center'>View More...</h4> 
				 
			  </Card.Body> 
			  </Link>
		  </Card> 
	  </Col> 
		  </Row>
		  </div>
  </>


  )
}

export default OzoneLaserMarkingMachineTouchScreenModel
