import React from "react";
import { Container, Image } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom';

function GreenHighValue() {
  return (
    <>
      <br />
      <br />
      <Container className='text-center'>
        <h2 className="text-center my-4" style={{color:"#199AD8"}} >
          Green Laser Machine for High Valued Performance
        </h2>
        <Image src=  './Images/Product Img/diamond/green 04.png'  style={{height:"100%",width:"50%"}}
		/>
		</Container>
		<Container>
        <p>
        
          a flagship laser machine, rapidly become most popular for processing
          high valued & bigger size stones. It generates highest level of yield.
          The compact & sealed Laser head is distinctively configured with end
          pumped resonator geometry using highly efficient frequency conversion
          technique. It is integrated with magnetic levitation technology that
          delivers fine-tuned thinnest laser beam which drastically reduce the
          cutting loss. We believe in continuous improvement elegantly designed
          using advanced laser technology to maximise your profit at very
          affordable cost. It delivers consistent and reliable performance in
          Sawing, Slicing, Coring of Lab Grown and Natural Diamonds.
        </p>

        <h3>Salient Features : </h3>

        <ul class="list-check">
          <li>
            Thinnest Laser beam in its class resulting in lowest cutting loss
            significantly.
          </li>

          <li>
            Superior pulse to pulse stability with inbuilt peak power
            regulations, imparts high safety in cutting process for round the
            clock operations without being affected to temperature fluctuations.
          </li>

          <li>
            Highly engineered and precisely sealed laser resonator, that ensures
            nil deviation in output power, caused due to vibration and change in
            environmental condition.
          </li>

          <li>Most economic machine with 95 % conversion efficiency.</li>

          <li>
            Full control of the repetition rate from single-shot to 50kHz.
          </li>

          <li>
            Absence of moving parts in linear motor and linear scale measures
            position at the load which results in high level of positioning
            resolution, repeatability & accuracy.
          </li>

          <li>
            No contacting parts in linear motion resulting in negligible
            services over its life cycle.
          </li>

          <li>
            Zero backlash as no mechanical transmission components in linear
            stages
          </li>

          <li>
            No windup, negligible friction and excellent dynamic responsiveness
            of linear motor generates outstanding counter accuracy and
            super-smooth velocity profile.
          </li>

          <li>
            Smart interlock system ensures safe and smooth running of machine.
            Excellent surface quality throughout the entire depth of cut
          </li>

          <li>
            Motorized direct driven 360⁰ batch production fixture for :{" "}
            <ul>
              <li>* Precise Single Side or Double Side Sawing </li>{" "}
              <li>
                * Accurate and precise auto pie sawing from two side or single
                side sawing{" "}
              </li>{" "}
              <li>
                * Multiple sawing feature for cutting extra piece in a single
                setting{" "}
              </li>{" "}
              <li>* Slicing of CVD with excellent surface finish </li>
            </ul>
          </li>

          <li>Batch production fixture for very accurate coring of CVD</li>

          <li>
            Skin banding option for eliminating risk of diamond breakage because
            of internal reflection.
          </li>

          <li>Compact and robust frame structure</li>
        </ul>
      </Container>




	  <Container className="mt-5 "> 
	  <Row className="justify-content-center"> 
		
		  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
			  <Card className='shado'> 
			  <Link to={"/IrGreen4p"} style={{textDecoration:"none"}}>
				  <Card.Img 
					  variant="top"
					  src= 
					  './Images/Product Img/diamond/green 01.png' 
				  /> 
				  <Card.Body > 
					  <h5 className='text-center'>IR/Green Laser Machine for 4P and Faceting operation of Round and Fancy Shapes</h5> 
					
				  </Card.Body>
				  </Link> 
			  </Card> 
		  </Col> 
	
		  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
		  <Card className='shado'> 
		  <Link to={"/GreenSawingShapeCVD"} style={{textDecoration:"none"}}>
			  <Card.Img 
				  variant="top"
				  src= 
				  './Images/Product Img/diamond/green 02.png' 
				  alt="GeeksforGeeks Logo"
			  /> 
			  <Card.Body className='shado'> 
			  <h5 className='text-center'>Green Laser Machine for Sawing ,Shape Cutting, CVD Coring and Slicing</h5> 
				 
			  </Card.Body> 
			  </Link>
		  </Card> 
	  </Col> 
	
	  <Col xs={12} md={6} lg={3} className="my-1 shado1"> 
	  <Card className='shado'> 
	  <Link to={"/GreenSawingCVDSlicing"} style={{textDecoration:"none"}}>
		  <Card.Img 
			  variant="top"
			  src= 
			  './Images/Product Img/diamond/green 03.png' 
			  alt="GeeksforGeeks Logo"
		  /> 
		  <Card.Body> 
		  <h5 className='text-center'>Green Laser Machine for Sawing, CVD Coring and Slicing</h5> 
			 
		  </Card.Body> 
		  </Link>
	  </Card> 
	</Col> 
	   
	</Row>
	</Container>
	

    </>
  );
}

export default GreenHighValue;
