import React from "react";
import Image from "react-bootstrap/esm/Image";
import Container from "react-bootstrap/esm/Container";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Whatsaapicon from "./components/Whatsaapicon";
import SliderProduct from "./SliderProduct";
import ScrollButton from "./components/ScrollButton";
import PopupForm from "./components/PopupForm";
// import Head from "next/head";

function Home() {
 
  document.title = "Easy Laser Tech, Surat - Manufacturer of Laser Marking and CO2 Laser Machine ";
  
  
  return (
    <>
    {/* <Head>
    <title>Easy Laser Tech, Surat - Manufacturer of Laser Marking System and CO2 Laser Cutting
    Machine</title>
    </Head> */}
      <Whatsaapicon />
      <ScrollButton />
      <PopupForm />
      <br />
      <br />
      <SliderProduct />
      <br />
      <Container>
        <Row style={{ borderBottom: "1px solid grey" }} >
          <Col xs={12} md={12} lg={6}>
            <div style={{ width: "100%"}} className="m-3 px-3 bgStyle">
              <div className="mt-5 ">
                <Card.Body >
                  <h2 style={{ color: "#199AD8",paddingTop:"10px"}}>About Us </h2>
                  
                  <Card.Text style={{ textAlign: "justify" }} >
                    We are a group of industry veterans in the field of Lasers
                    and analytical instruments. Easy Laser Tech provides
                    innovative, robust, and safe Laser solutions for the most
                    demanding industries and jewellery applications.
                  </Card.Text>
                  <p style={{ textAlign: "justify" }}>
                    {" "}
                    Relying on a team of Laser & Analytical Technology experts
                    with over 15 years of experience in the same field, we offer
                    a complete range of products for jewellery applications such
                    as Gold Testing Machines (XRF Analyzers), Laser Marking,
                    Laser Cutting, and Laser Engraving Machine.Also co2 laser
                    cuuting,marking,engraving of wooden and acrylic materils
                    etc.
                  </p>
                  <p style={{ textAlign: "justify"}}>
                    What started as a spark of vision in 2019 has today soared
                    into a dominant voice in the laser industry on the wings of
                    our dedicated associates.
                  </p>
                </Card.Body>
              </div>
            </div>
          </Col>
          <br />
          <Col
            xs={12}
            md={8}
            lg={6}
            
            className="mt-5"
          >
            <Image
              src="./Images/home about.jpg"
              style={{ height: "100%", width: "100%" }}
              className="rounded-3"
            />
          </Col>
        </Row>
      </Container>
      <br />

      <Container>
        <h2 style={{ color: "#199AD8" }} className="my-4 text-center">
          Our Laser Machine Products Range
        </h2>
        <p style={{ textAlign: "justify" }} className="bg-color">
          We “Easy Laser Tech” are a Sole Proprietorship firm, engaged in
          Manufacturer, Wholesaler, Exporter and Trader an optimum quality range
          of Laser Marking Machine, Laser Hallmarking Machine and much more. We
          are providing SS Laser Marking Job Work Service and Laser Machine
          Repairing Service to our client.
        </p>
      </Container>

      <Container className="mt-5 ">
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={3} className="my-1">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/Fiber- Laser-marking-Machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body>
                <h4 className="text-center">Fiber Laser Marking Machine</h4>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3} className="my-1">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/Fiber-Laser-Cutting-Machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body>
                <h4 className="text-center">Fiber Laser Cutting Machine</h4>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1 ">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/Laser-Welding-Machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body className="shad">
                <h4 className="text-center">Fiber Laser Welding Machine</h4>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1 ">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/online laser marking machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body>
                <h4 className="text-center">Online Laser Marking Machine</h4>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/Co2 Laser Cutting Machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body>
                <h4 className="text-center">
                  Co2 Laser Cutting & Engraving Machine
                </h4>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3} className="my-1">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/Co2 Engraving Machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body>
                <h4 className="text-center">Co2 Laser Engraving Machine</h4>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1">
            <Card className="shad">
              <Card.Img
                variant="top"
                src="./Images/Product Img/UV-Laser-machine.png"
                alt="GeeksforGeeks Logo"
              />
              <Card.Body>
                <h4 className="text-center">UV Laser Marking Machine</h4>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <div className="shado border">
              <div>
                <Card.Title className=" fs-2" style={{ margin: "126px" }}>
                  {" "}
                  <Link to={"/products"} style={{ textDecoration: "none" }}>
                    See More Products...
                  </Link>
                </Card.Title>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <h2 style={{ color: "#199AD8" }} className="my-4 text-center">
          Our Diamond Machine Products Range
        </h2>
      </Container>

      <Container>
        <Row>
          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link to={"/multisawing"} style={{ textDecoration: "none" }}>
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/MULTIPIE SAWING MACHINE 1.jpg"
                  alt="GeeksforGeeks Logo"
                />
                <Card.Body>
                  <h4 className="text-center">Multipie Sawing Machine</h4>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link to={"/irgelvo"} style={{ textDecoration: "none" }}>
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/IR-Gelvo-Fency-4p-1.jpg"
                  alt="GeeksforGeeks Logo"
                />
                <Card.Body>
                  <h4 className="text-center">IR Gelvo Fency 4P </h4>
                </Card.Body>
              </Link>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link to={"/fibergelvo"} style={{ textDecoration: "none" }}>
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/FIBER-Gelvo-Fency-4p-1.jpg"
                  alt="GeeksforGeeks Logo"
                />
                <Card.Body>
                  <h4 className="text-center">Fiber Gelvo Fency 4P</h4>
                </Card.Body>
              </Link>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link to={"/cvdcorning"} style={{ textDecoration: "none" }}>
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/CVD-Coring-&-face-cut-FIBER-1.jpg"
                  alt="GeeksforGeeks Logo"
                />
                <Card.Body>
                  <h5 className="text-center">CVD Coring & Face Cut Fiber</h5>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        </Row>
      </Container>

      <br />

      <Container className="mt-5 ">
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link to={"/GreenHighValue"} style={{ textDecoration: "none" }}>
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/green 04.png"
                  alt="GeeksforGeeks Logo"
                />

                <Card.Body>
                  <h5 className="text-center">
                    Green Laser Machine for High Valued Performance
                  </h5>
                </Card.Body>
              </Link>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link to={"/IrGreen4p"} style={{ textDecoration: "none" }}>
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/green 01.png"
                />
                <Card.Body>
                  <h5 className="text-center">
                    IR/Green Laser Machine for 4P and Faceting operation of
                    Round and Fancy Shapes
                  </h5>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link
                to={"/GreenSawingShapeCVD"}
                style={{ textDecoration: "none" }}
              >
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/green 02.png"
                  alt="GeeksforGeeks Logo"
                />
                <Card.Body className="shado">
                  <h5 className="text-center">
                    Green Laser Machine for Sawing ,Shape Cutting, CVD Coring
                    and Slicing
                  </h5>
                </Card.Body>
              </Link>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={3} className="my-1 shado1">
            <Card className="shado">
              <Link
                to={"/GreenSawingCVDSlicing"}
                style={{ textDecoration: "none" }}
              >
                <Card.Img
                  variant="top"
                  src="./Images/Product Img/diamond/green 03.png"
                  alt="GeeksforGeeks Logo"
                />
                <Card.Body>
                  <h5 className="text-center">
                    Green Laser Machine for Sawing, CVD Coring and Slicing
                  </h5>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        </Row>
      </Container>

      <div class="text-center my-5 fs-2">
        <h2 style={{ color: "#199AD8" }}>
          View More Diamond Products Cataloge
        </h2>
        <a href="./Images/01.pdf" target="_blank">
          <Image
            src="./Images/social media/pdf-download-icon.png"
            alt="pdf icin"
            style={{ height: "120px", width: "250px" }}
          />
        </a>
      </div>

      <br />
      <div
        className="text-center"
        style={{
          backgroundImage: `url("./Images/background/contact-home.jpg")`,
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <h2
            className=" text-uppercase text-center my-2 "
            style={{
              background: "#6896DA",
              color: "white",
              fontFamily: "italic",
            }}
          >
            TELL US WHAT ARE YOU LOOKING FOR ?
          </h2>
          <Container>
            <form
              action="https://formspree.io/f/mjvnonpl"
              method="POST"
              className="col-7 container text-center my-4"
              target="_blank"
            >
              <div className="form-group ">
                <input
                  type="text"
                  name="name"
                  className="form-control mb-4"
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  className="form-control mb-4"
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  name="number"
                  className="form-control mb-4"
                  placeholder="Mobile Number"
                />
              </div>

              <div className="form-group mb-4">
                <textarea
                  className="form-control rounded-1"
                  name="requirement"
                  id="Textarea2"
                  rows="3"
                  placeholder="Requirement"
                  autocomplete="off"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="px-5 rounded-1 p-1 my-2"
                style={{
                  background: "#0099DA",
                  color: "white",
                  border: "none",
                }}
              >
                Submit
              </button>
            </form>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Home;
